<template>
  <div>
    <h4 class="mt-1">Permisos de Usuarios</h4>
    <b-tabs content-class="mt-3">
      <b-tab title="Administrar Permisos de Usuarios">
        <PermissionsTable></PermissionsTable>
      </b-tab>
      <!-- **************************************** Pestaña de Recuadro Roles y Funciones **************************************** -->
      <b-tab title="Asignar Cargos">
        <GroupNameAssignment></GroupNameAssignment>
      </b-tab>
      <b-tab title="Ver Grupos">
        <RoleAccessTable></RoleAccessTable>
        <div class="info">
          <h5 class="info-title">
            <b-icon icon="info-circle"></b-icon>Simbología / Significados
          </h5>
          <div class="info-container">
            <div class="row">
              <b-icon icon="check-square" variant="success" class="col">
              </b-icon>
              <p class="col">
                Acceso total a las características, permitiendo ver, crear,
                modificar y eliminar distintos elementos de la plataforma.
              </p>
            </div>
            <div class="row">
              <b-icon icon="question-square" variant="warning" class="col">
              </b-icon>
              <p class="col">
                Se permite ver, crear y modificar elementos dentro de la
                plataforma.
              </p>
            </div>
            <div class="row">
              <b-icon icon="plus-square" variant="info" class="col"> </b-icon>
              <p class="col">
                Está permitido tanto visualizar como crear elementos de la
                plataforma.
              </p>
            </div>
            <div class="row">
              <b-icon icon="pencil-square" variant="info" class="col"> </b-icon>
              <p class="col">
                Se permiten las acciones de ver y modificar elementos de la
                plataforma.
              </p>
            </div>
            <div class="row">
              <b-icon icon="x-square" variant="danger" class="col"> </b-icon>
              <p class="col">Sólo está permitida la vista de elementos.</p>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "PermissionsView",
  props: {},
  components: {
    RoleAccessTable: () => import("./RoleAccessTable"),
    GroupNameAssignment: () => import("./GroupNameAssignment"),
    PermissionsTable: () => import("./PermissionsTable"),
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      campuses: names.CAMPUSES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      matters: names.MATTERS,
    }),
  },
  methods: {
    fetchRoleNames() {
      this.$restful.Get(`/teaching/roles/`).then((response) => {
        this.role_names = response;
        console.log(this.role_names);
      });
    },

    displayRoleSelectors() {
      if (this.selected_role == 2 && this.selected_role == 5);
      return (this.is_active = true);
    },
  },
  created() {
    this.fetchRoleNames();
    this.$store.dispatch(names.FETCH_USERS);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_MATTERS);
    // TODO: Crear APIView que devuelva todos los datos necesarios de una vez.
  },
};
</script>

<style scoped>
.user-groups {
  display: flex;
  width: 100%;
}
.user-card,
.role-card {
  width: 50%;
  box-shadow: 1px 2px 4px -2px rgb(92, 92, 92);
}
option{
  position: relative !important;
}
/* #user-select{
    transform: translate3d(5px, 35px, 0px)!important;
} */
.multi-select-card {
  box-shadow: 1px 2px 4px -2px rgb(92, 92, 92);
  border-style: none !important;
}
.select-card {
  box-shadow: 1px 2px 4px -2px rgb(92, 92, 92);
}
.card-body {
  padding: 3%;
}
.card-header {
  background-color: var(--primary-color);
}
.multi-select-body {
  padding: 0%;
  margin: 0%;
}
.custom-select,
.option {
  cursor: pointer;
}

.info {
  background-color: var(--primary-color);
  border-radius: 10px;
  margin-top: 1.8%;
  padding: 0 2% 2% 2%;
}
.info-container {
  background-color: var(--secondary-font-color);
  border-radius: 10px;
}
.info-title {
  text-align: left;
  padding-top: 1%;
  margin-bottom: 2%;
}
.info-title .b-icon {
  margin-right: 1%;
}
.info .b-icon {
  max-width: 2rem;
  margin-left: 1%;
}
.info p {
  text-align: left;
  margin-top: -0.4%;
  margin-bottom: 0;
}
</style>